<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { mapGetters } from 'vuex';
export default {
  components:{
    VueSlider
  },
  props: {
    input:{
      type: Object,
      required: false,
    },
    pdf:{
      type: Boolean,
      required: false
    },
    riskProfiles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  created () {
    
    if(this.input) {
      this.riskTolerance = this.input["RiskTolerance"];
      this.selectedProfile = this.riskProfiles.find(x => x.id === this.input["RiskProfileId"]);
      this.solutionData = {...this.input};
      this.ignoreProps = true;
      this.recalculate();
    } else {
      this.selectedProfile = this.riskProfiles.length > 0 ? this.riskProfiles[0] : null;
     
      if(this.selectedProfile) {
        this.solutionData = {...this.selectedProfile.solutionData};
        this.recalculate();
      }
     
      this.$nextTick(() =>{
        this.$emit('apply', this.createSolutionData());
      });
    }
     this.$nextTick(() =>{
        this.initializing = false;
      });
  },
  data() {
    return {
      initializing: true,
      selectedProfile: null,
      solutionData: null,
      ignoreProps: false,
      editMode: true,
      riskTolerance: 'profile',
      assetLimits: this.createAssetLimits(),
      limits:{
        fractionEquities: {
          min: 0,
          max: 100
        },
        fractionBonds: {
          min: 0,
          max: 100
        },
        fractionAlternatives: {
          min: 0,
          max: 100
        },
        annualReturn: {
          min: -20,
          max: 100
        },
        maxDrawdown: {
          min: 0,
          max: 100
        },
        bounds: {
          min: 0,
          max: 100
        }
      }
    };
  },
  computed: {
    ...mapGetters('riskprofiles', {
      loading: 'loading',
    //  riskProfiles: 'activeItems'
    }),
  },
  watch:{
    selectedProfile (newValue, oldValue) {
      if(newValue !== oldValue && !this.initializing) {
        console.log('selectedProfile changed');
        this.ignoreProps = false;

        if(!this.selectedProfile)
          this.selectedProfile = this.riskProfiles.length > 0 ? this.riskProfiles[0] : null;

        this.solutionData = {...this.selectedProfile.solutionData};
        this.recalculate();
        this.apply();
      }
    },
    riskTolerance (newValue, oldValue) {
      if(newValue !== oldValue && !this.initializing) {
        this.ignoreProps = false;
        this.recalculate();
        this.apply();
      }
    }
  },
  methods: {
    startEdit() {
      this.editMode = true;
    },
    cancelEdit() {
      this.editMode = false;
    },
    apply(){
      this.ignoreProps = true;
      this.$emit('apply', this.createSolutionData());
     // this.editMode = false;
    },
    createSolutionData () {
      const o = {
        ...this.solutionData,

        DrawDownMax: this.assetLimits.maxDrawdown/100,

        AnnRtnMin: this.assetLimits.annualReturn[0]/100,
        AnnRtnMax: this.assetLimits.annualReturn[1]/100,

        FracEquityMin: this.assetLimits.fractionEquities[0]/100,
        FracEquityMax: this.assetLimits.fractionEquities[1]/100,

        FracBondMin: this.assetLimits.fractionBonds[0]/100,
        FracBondMax: this.assetLimits.fractionBonds[1]/100,

        FracAltMin: this.assetLimits.fractionAlternatives[0]/100,
        FracAltMax: this.assetLimits.fractionAlternatives[1]/100,

        WeightMin: this.assetLimits.bounds[0]/100,
        WeightMax: this.assetLimits.bounds[1]/100,

        RiskTolerance: this.riskTolerance,
        RiskProfileId: (this.selectedProfile || {}).id,
        SolutionName: (this.selectedProfile || {}).solutionName,
      };
      return o;
    },
    createAssetLimits () {
      return {
        fractionEquities: [0, 100],
        fractionBonds: [0, 100],
        fractionAlternatives: [0, 100],
        annualReturn: [0, 100],
        maxDrawdown: 0,
        bounds: [0, 100]
      };
    },
    recalculate (){
      console.log('recalculate', this.solutionData);

      let assetLimits = this.createAssetLimits();

      assetLimits.maxDrawdown = (this.solutionData.DrawDownMax || 0) * 100;

      assetLimits.annualReturn[0] = (this.solutionData.AnnRtnMin || 0) * 100;
      assetLimits.annualReturn[1] = (this.solutionData.AnnRtnMax || 0) * 100;

      assetLimits.fractionEquities[0] = (this.solutionData.FracEquityMin || 0) * 100;
      assetLimits.fractionEquities[1] = (this.solutionData.FracEquityMax || 0) * 100;

      assetLimits.fractionBonds[0] = (this.solutionData.FracBondMin || 0) * 100;
      assetLimits.fractionBonds[1] = (this.solutionData.FracBondMax || 0) * 100;

      assetLimits.fractionAlternatives[0] = (this.solutionData.FracAltMin || 0) * 100;
      assetLimits.fractionAlternatives[1] = (this.solutionData.FracAltMax || 0) * 100;

      assetLimits.bounds[0] = (this.solutionData.WeightMin || 0) * 100;
      assetLimits.bounds[1] = (this.solutionData.WeightMax || 0) * 100;

      if(!this.ignoreProps) {

        if(this.riskTolerance === 'increase') {

          assetLimits.maxDrawdown = assetLimits.maxDrawdown + (assetLimits.maxDrawdown * 0.1);

          assetLimits.annualReturn[0] = assetLimits.annualReturn[0] - (Math.abs(assetLimits.annualReturn[0]) * 0.1);
          assetLimits.annualReturn[1] = assetLimits.annualReturn[1] + (Math.abs(assetLimits.annualReturn[1]) * 0.1);

          assetLimits.fractionEquities[1] = assetLimits.fractionEquities[1] + (Math.abs(assetLimits.fractionEquities[1]) * 0.2);

          assetLimits.fractionBonds[0] = Math.max(0, assetLimits.fractionBonds[0] - (Math.abs(assetLimits.fractionBonds[1]) * 0.2));

          assetLimits.fractionAlternatives[1] = assetLimits.fractionAlternatives[1] + (Math.abs(assetLimits.fractionAlternatives[1]) * 0.2);

        } else if(this.riskTolerance === 'decrease') {

          assetLimits.maxDrawdown = assetLimits.maxDrawdown - (assetLimits.maxDrawdown * 0.05);
          if(assetLimits.maxDrawdown < 10) {
            assetLimits.maxDrawdown = 10;
          }

          assetLimits.annualReturn[0] = assetLimits.annualReturn[0] + (Math.abs(assetLimits.annualReturn[0]) * 0.025);
          if(assetLimits.annualReturn[0] > -10) {
            assetLimits.annualReturn[0] = -10;
          }
          assetLimits.annualReturn[1] = assetLimits.annualReturn[1] - (Math.abs(assetLimits.annualReturn[1]) * 0.025);
          if(this.solutionData.TAAPctTarget) {
            const limit = (this.solutionData.TAAPctTarget * 100) + (this.solutionData.TAAPctTarget * 100 * 0.025);
            if(assetLimits.annualReturn[0] > limit) {
              assetLimits.annualReturn[0] = limit;
            }
          }

          assetLimits.fractionEquities[1] = assetLimits.fractionEquities[1] - (Math.abs(assetLimits.fractionEquities[1]) * 0.1);
          if(assetLimits.maxDrawdown > 50) {
            assetLimits.maxDrawdown = 50;
          }

          assetLimits.fractionAlternatives[1] = assetLimits.fractionAlternatives[1] - (Math.abs(assetLimits.fractionAlternatives[1]) * 0.1);
          if(assetLimits.maxDrawdown < 0) {
            assetLimits.maxDrawdown = 0;
          }
        }

      }

      if(assetLimits.annualReturn[0] < this.limits.annualReturn.min) assetLimits.annualReturn[0] = this.limits.annualReturn.min;
      if(assetLimits.annualReturn[0] > this.limits.annualReturn.max) assetLimits.annualReturn[0] = this.limits.annualReturn.max;
      if(assetLimits.annualReturn[1] < this.limits.annualReturn.min) assetLimits.annualReturn[1] = this.limits.annualReturn.min;
      if(assetLimits.annualReturn[1] > this.limits.annualReturn.max) assetLimits.annualReturn[1] = this.limits.annualReturn.max;

      if(assetLimits.fractionEquities[0] < this.limits.fractionEquities.min) assetLimits.fractionEquities[0] = this.limits.fractionEquities.min;
      if(assetLimits.fractionEquities[0] > this.limits.fractionEquities.max) assetLimits.fractionEquities[0] = this.limits.fractionEquities.max;
      if(assetLimits.fractionEquities[1] < this.limits.fractionEquities.min) assetLimits.fractionEquities[1] = this.limits.fractionEquities.min;
      if(assetLimits.fractionEquities[1] > this.limits.fractionEquities.max) assetLimits.fractionEquities[1] = this.limits.fractionEquities.max;

      if(assetLimits.fractionBonds[0] < this.limits.fractionBonds.min) assetLimits.fractionBonds[0] = this.limits.fractionBonds.min;
      if(assetLimits.fractionBonds[0] > this.limits.fractionBonds.max) assetLimits.fractionBonds[0] = this.limits.fractionBonds.max;
      if(assetLimits.fractionBonds[1] < this.limits.fractionBonds.min) assetLimits.fractionBonds[1] = this.limits.fractionBonds.min;
      if(assetLimits.fractionBonds[1] > this.limits.fractionBonds.max) assetLimits.fractionBonds[1] = this.limits.fractionBonds.max;

      
      if(assetLimits.fractionAlternatives[0] < this.limits.fractionAlternatives.min) assetLimits.fractionAlternatives[0] = this.limits.fractionAlternatives.min;
      if(assetLimits.fractionAlternatives[0] > this.limits.fractionAlternatives.max) assetLimits.fractionAlternatives[0] = this.limits.fractionAlternatives.max;
      if(assetLimits.fractionAlternatives[1] < this.limits.fractionAlternatives.min) assetLimits.fractionAlternatives[1] = this.limits.fractionAlternatives.min;
      if(assetLimits.fractionAlternatives[1] > this.limits.fractionAlternatives.max) assetLimits.fractionAlternatives[1] = this.limits.fractionAlternatives.max;

      if(assetLimits.bounds[0] < this.limits.bounds.min) assetLimits.bounds[0] = this.limits.bounds.min;
      if(assetLimits.bounds[0] > this.limits.bounds.max) assetLimits.bounds[0] = this.limits.bounds.max;
      if(assetLimits.bounds[1] < this.limits.bounds.min) assetLimits.bounds[1] = this.limits.bounds.min;
      if(assetLimits.bounds[1] > this.limits.bounds.max) assetLimits.bounds[1] = this.limits.bounds.max;

      assetLimits.maxDrawdown = parseFloat(assetLimits.maxDrawdown.toFixed(3));
      assetLimits.annualReturn[0] = parseFloat(assetLimits.annualReturn[0].toFixed(3));
      assetLimits.annualReturn[1] = parseFloat(assetLimits.annualReturn[1].toFixed(3));
      assetLimits.fractionEquities[0] = parseFloat(assetLimits.fractionEquities[0].toFixed(3));
      assetLimits.fractionEquities[1] = parseFloat(assetLimits.fractionEquities[1].toFixed(3));
      assetLimits.fractionBonds[0] = parseFloat(assetLimits.fractionBonds[0].toFixed(3));
      assetLimits.fractionBonds[1] = parseFloat(assetLimits.fractionBonds[1].toFixed(3));
      assetLimits.fractionAlternatives[0] = parseFloat(assetLimits.fractionAlternatives[0].toFixed(3));
      assetLimits.fractionAlternatives[1] = parseFloat(assetLimits.fractionAlternatives[1].toFixed(3));
      assetLimits.bounds[0] = parseFloat(assetLimits.bounds[0].toFixed(3));
      assetLimits.bounds[1] = parseFloat(assetLimits.bounds[1].toFixed(3));

      this.assetLimits = assetLimits;

        console.log('recalculate assetLimits', this.assetLimits);
    }
  }
};
</script>

<template>
  <div>
    <div v-if="!pdf">
      <div class="card cardc8">
        <div class="card-content m-3">
          <div class="card-header bg-transparent d-flex justify-content-between"><h5>Risk Profile</h5></div>
          <div v-if="riskProfiles.length === 0">
            <em class="text-muted">You don't have any profiles yet.</em>
          </div>
          <select class="form-control" v-model="selectedProfile">
            <option v-for="profile in riskProfiles" :key="profile.id" :value="profile">
              {{profile.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="card cardc8">
        <div class="card-content m-3">
          <div class="card-header bg-transparent d-flex justify-content-between"><h5>Risk Tolerance</h5></div>
          <b-form-group class="">
            <b-form-radio class="mt-1" v-model="riskTolerance" name="riskTolerance" value="increase">Increase</b-form-radio>
            <b-form-radio class="mt-1" v-model="riskTolerance" name="riskTolerance" value="profile">As Risk Profile</b-form-radio>
            <b-form-radio class="mt-1" v-model="riskTolerance" name="riskTolerance" value="decrease">Decrease</b-form-radio>
          </b-form-group>
        </div>
      </div>

      <div class="card cardc8" v-if="!editMode">
        <div class="card-content m-3">
          <div class="card-header bg-transparent d-flex justify-content-between"><h5>Asset Limits</h5></div>
          <div class="">
          <table class="table-borderless mb-0">
            <thead>
              <tr>
                <th class="col-8"></th>
                <th class="col-2"></th>
                <th class="col-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="col-8">Equities, %</td>
                <td class="col-2 text-right "><b>{{assetLimits.fractionEquities[0]}}</b></td>
                <td class="col-2 text-right"><b>{{assetLimits.fractionEquities[1]}}</b></td>
              </tr>
              <tr>
                <td class="col-8">Bonds, %</td>
                <td class="col-2 text-right" ><b>{{assetLimits.fractionBonds[0]}}</b></td>
                <td class="col-2 text-right"><b>{{assetLimits.fractionBonds[1]}}</b></td>
              </tr>
              <tr>
                <td class="col-8">Alternatives, %</td>
                <td class="col-2 text-right"><b>{{assetLimits.fractionAlternatives[0]}}</b></td>
                <td class="col-2 text-right"><b>{{assetLimits.fractionAlternatives[1]}}</b></td>
              </tr>
              <tr>
                <td class="col-8">Annual Return, %</td>
                <td class="col-2 text-right"><b>{{assetLimits.annualReturn[0]}}</b></td>
                <td class="col-2 text-right"><b>{{assetLimits.annualReturn[1]}}</b></td>
              </tr>
              <tr>
                <td class="col-8">Max Drawdown, %</td>
                <td colspan="2" class="col-3 text-right"><b>{{assetLimits.maxDrawdown}}</b></td>
    
              </tr>
              <tr>
                <td class="col-8">Bounds, %</td>
                <td class="col-2 text-right"><b>{{assetLimits.bounds[0]}}</b></td>
                <td class="col-2 text-right"><b>{{assetLimits.bounds[1]}}</b></td>
              </tr>
            </tbody>
          </table>
            <button class="btn  btn-sm btn-c8 float-right mt-3"  @click="startEdit">
                <span class="btn-content">
                  <i class="fa fa-pen mr-2"/> Edit
                </span>
              </button>
          </div>
        </div>
      </div>

      <div class="card cardc8" v-if="editMode">
        <div class="card-content m-3">
          <div class="card-header bg-transparent d-flex justify-content-between"><h5>Asset Limits</h5></div>
          
          <div>
            <label class="">Equities</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                <input class="form-control form-control-sm " style="width:45px"  v-model="assetLimits.fractionEquities[0]" @keyup="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;" v-model="assetLimits.fractionEquities" :min="limits.fractionEquities.min" :max="limits.fractionEquities.max" :interval="1" @change="apply"></vue-slider>
              </div>
              <div class="col" style="max-width:60px; padding-left:5px;">
                <input class="form-control form-control-sm" style="width:45px"  v-model="assetLimits.fractionEquities[1]" @keyup="apply"/>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Bonds</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                <input class="form-control form-control-sm " style="width:45px"  v-model="assetLimits.fractionBonds[0]" @keyup="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;" v-model="assetLimits.fractionBonds" :min="limits.fractionBonds.min" :max="limits.fractionBonds.max" :interval="1" @change="apply"></vue-slider>
              </div>
              <div class="col" style="max-width:60px; padding-left:5px;">
                <input class="form-control form-control-sm" style="width:45px"  v-model="assetLimits.fractionBonds[1]" @keyup="apply"/>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Alternatives</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                <input class="form-control form-control-sm " style="width:45px"  v-model="assetLimits.fractionAlternatives[0]" @keyup="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;" v-model="assetLimits.fractionAlternatives" :min="limits.fractionAlternatives.min" :max="limits.fractionAlternatives.max"  :interval="1" @change="apply"></vue-slider> </div>
              <div class="col" style="max-width:60px; padding-left:5px;">
                <input class="form-control form-control-sm " style="width:45px"  v-model="assetLimits.fractionAlternatives[1]" @keyup="apply"/>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Annual Return</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                <input class="form-control form-control-sm " style="width:44px"  v-model="assetLimits.annualReturn[0]" @change="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;"  v-model="assetLimits.annualReturn" :min="limits.annualReturn.min" :max="limits.annualReturn.max" :interval="1" @change="apply"></vue-slider> </div>
              <div class="col" style="max-width:60px; padding-left:5px;">
                <input class="form-control form-control-sm " style="width:45px"  v-model="assetLimits.annualReturn[1]" @change="apply"/>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Max Drawdown</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                  <input class="form-control form-control-sm " style="width:45px" v-model="assetLimits.maxDrawdown" @change="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;"  v-model="assetLimits.maxDrawdown" :min="limits.maxDrawdown.min" :max="limits.maxDrawdown.max" :interval="1" @change="apply"></vue-slider> </div>
              <div class="col" style="max-width:60px; width:60px; padding-left:5px;">

              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Lower and Upper Bounds</label>
            <div class="row">
              <div class="col" style="max-width:60px">
                <input class="form-control form-control-sm " style="width:45px" v-model="assetLimits.bounds[0]" @change="apply"/>
              </div>
              <div class="col" style="padding-left:10px;padding-right:10px;">
                <vue-slider style="margin-top:5px;"  v-model="assetLimits.bounds" :min="limits.bounds.min" :max="limits.bounds.max" :interval="1" @change="apply"></vue-slider> </div>
              <div class="col" style="max-width:60px; padding-left:5px;">
                <input class="form-control form-control-sm "  style="width:45px" v-model="assetLimits.bounds[1]" @change="apply"/>
              </div>
            </div>
          </div>
          <!-- <button class=" mt-2 btn btn-light rounded-pill mt-4" @click="cancelEdit">
            Cancel
          </button> -->
          <!-- <button class=" mt-2 btn btn-c8 float-right mt-4" @click="apply">
            <span class="btn-content">
              <i class="fa fa-save mr-2"></i>
              Apply Changes
            </span>
          </button> -->
        </div>

      
      </div>
    </div>

    <div class="row" v-if="pdf">
      <div class="col-6">
        <div class="card cardc8">
          <div class="card-content m-3">
            <div class="card-header bg-transparent d-flex justify-content-between">
              <h5 class="">Base Risk Profile</h5>
            </div>
            <p class="card-text" v-if="selectedProfile">{{ selectedProfile.name }}</p>
            <div v-if="riskProfiles.length === 0">
              <em class="text-muted">You don't have any profiles yet.</em>
            </div>
          </div>
        </div>  
      </div>

      <div class="col-6">
        <div class="card cardc8">
          <div class="card-content m-3">
            <div class="card-header bg-transparent d-flex justify-content-between">
              <h5>Asset Limits</h5>
            </div>
            <div>
              <table class="table-borderless mb-0">
                <thead>
                  <tr>
                    <th class="col-8"></th>
                    <th class="col-2"></th>
                    <th class="col-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-8">Equities, %</td>
                    <td class="col-2 text-right "><b>{{assetLimits.fractionEquities[0]}}</b></td>
                    <td class="col-2 text-right"><b>{{assetLimits.fractionEquities[1]}}</b></td>
                  </tr>
                  <tr>
                    <td class="col-8">Bonds, %</td>
                    <td class="col-2 text-right" ><b>{{assetLimits.fractionBonds[0]}}</b></td>
                    <td class="col-2 text-right"><b>{{assetLimits.fractionBonds[1]}}</b></td>
                  </tr>
                  <tr>
                    <td class="col-8">Alternatives, %</td>
                    <td class="col-2 text-right"><b>{{assetLimits.fractionAlternatives[0]}}</b></td>
                    <td class="col-2 text-right"><b>{{assetLimits.fractionAlternatives[1]}}</b></td>
                  </tr>
                  <tr>
                    <td class="col-8">Annual Return, %</td>
                    <td class="col-2 text-right"><b>{{assetLimits.annualReturn[0]}}</b></td>
                    <td class="col-2 text-right"><b>{{assetLimits.annualReturn[1]}}</b></td>
                  </tr>
                  <tr>
                    <td class="col-8">Max Drawdown, %</td>
                    <td colspan="2" class="col-3 text-right"><b>{{assetLimits.maxDrawdown}}</b></td>
        
                  </tr>
                  <tr>
                    <td class="col-8">Bounds, %</td>
                    <td class="col-2 text-right"><b>{{assetLimits.bounds[0]}}</b></td>
                    <td class="col-2 text-right"><b>{{assetLimits.bounds[1]}}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>