<script>


export default {
 
  props: {
     stat: {
      type: Object,
      required: false
    },
    instruments: {
      type: Array,
      required: true
    },
    showOnlyFirst: {
      type: Boolean,
      required: false
    },
    firstTitle: {
      type: String,
      required: false
    }
  },
  computed: {
     hasStat() {
       return this.stat || false;
     }
  }
};
</script>

<template>
  <div  class="card cardc8" style="height:100%; ">
    <div  class="card-content">
      <div class="card-body">

        <!-- Header -->
        <div class="row" >
          <div class="col">
            <h5 class="card-title">Statistics</h5>
          </div>
        </div>

        <div class="table-responsive" style="position: relative; ">
          <em class="text-muted" v-if="!hasStat">No data found...</em>
          <table v-if="hasStat"
            class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap"
            style="border-collapse: collapse; border-spacing: 0; width: 100%;"
          >
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th>
                  <b v-if="firstTitle">{{firstTitle}}</b>
                </th>
              
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Annualised Return</td>
                <td>{{stat.annualisedReturn}}%</td>
              
              </tr>
              <tr>
                <td>Realised Volatility</td>
                <td>{{stat.realisedVolatility}}%</td>
                
              </tr>
              <tr>
                <td>Downside Volatility</td>
                <td>{{stat.downsideVolatility}}%</td>
               
              </tr>
              <tr>
                <td>Max Drawdown</td>
                <td>{{stat.maxDrawdown}}%</td>
             
              </tr>
              <tr>
                <td>Winning months</td>
                <td>{{stat.winningMonths}}%</td>
              
              </tr>
              <tr>
                <td>Sharpe Ratio</td>
                <td>{{stat.sharpeRatio}}</td>
                
              </tr>
              <tr>
                <td>Sortino Ratio</td>
                <td>{{stat.sortinoRatio}}</td>
              
              </tr>
              <tr>
                <td>Calmar Ratio</td>
                <td>{{stat.calmarRatio}}</td>
                
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
 
</template>