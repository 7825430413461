<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import Parameters from './portfolio-parameters'
import List from "@/views/pages/instruments/list";
import http from '@/oauth-client'
import FilterPanel from "@/views/pages/instruments/filter";
import MainStat from './components/main-stat'
import NetAssetValueGrowthChart from './components/net-asset-value-growth-chart'
import MonthlyPerformance from './components/monthly-performance'
import Weights from './components//weights'
import Swal from "sweetalert2";
import capitalize from 'lodash/capitalize'
import VueSlider from 'vue-slider-component'
import VueHtml2pdf from 'vue-html2pdf'
import 'vue-slider-component/theme/default.css'
import explorerHttp from '@/explorer-client'
import chatgpt from '@/components/chatgpt.vue';
import moment from 'moment'

const signalR = require("@microsoft/signalr");

export default {
  components: {
    Layout,
    PageHeader,
    Parameters,
    List,
    FilterPanel,
    MainStat,
    NetAssetValueGrowthChart,
    MonthlyPerformance,
    Weights,
    VueSlider,
    VueHtml2pdf,
    chatgpt
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    clone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    
    return {
      makingOptimization: false,
     

      predefinedRiskProfiles: [],
      invalidName: false,
      statColumns: {
        geography:{
          visible: true
        },
        provider:{
          visible: true
        },
        indexType:{
          visible: true
        },
        indexSubType:{
          visible: true
        },
        statUpdatedAt:{
          visible: true
        },
        instrumentType:{
          visible: true
        },
        assetClass:{
          visible: true
        },
        rebalanceFrequency:{
          visible: true
        },
        mtd:{
          visible: true
        },
        ytd:{
          visible: true
        },

        annualisedReturn:{
          visible: true
        },
        realisedVolatility:{
          visible: true
        },
        downsideVolatility:{
          visible: true
        },
        maxDrawdown:{
          visible: true
        },
        winningMonths:{
          visible: true
        },
        sharpeRatio:{
          visible: true
        },
        sortinoRatio:{
          visible: true
        },
        calmarRatio:{
          visible: true
        },
        externalId:{
          visible: false
        },
      },
      
      initialized: false,

      normalised: false,

      modified: false,

      startDate: null,
      endDate: null,
     
      editInstrumentsEnabled: false,

      pdfProcessing: false,
  
      title: 'New Portfolio',
      breadcrumbs: [
        
        {
          text: 'New Portfolio',
          active: true
        }
      ],
      initializing: false,
    
      defaults:[],
      selectedInstruments: {

      },
      instruments: {

      },
      weightsMode: false,
      instrumentWeights: {

      },
      parametersInput:null,

      name: 'My Portfolio ' + `${moment().format('YYYY-MM-DD')}`,
      currency: (this.currentUser || {}).defaultCurrency || 'USD',
      amount: 0,
      benchmark: (this.currentUser || {}).defaultBenchmark || 'SP500',
      constraints: null
    };
  },
  beforeDestroy(){
    this.setIDs([]);
  },
  created() {
    
    const uri = process.env.VUE_APP_BASE_URL + "/client-notifications";
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(uri, { 
        accessTokenFactory: () => localStorage.getItem('token')
      })
      .withAutomaticReconnect()
      .build();

    this.connection.on("RefreshIndex", indexId => {
  
      if(this.id === indexId) {
        this.load();
        this.loadOptimizationHistory();
        window.setTimeout(this.load, 5000);
      }
    });

    this.connection.onreconnecting(error => {
        console.log('signlaR onreconnecting connectionid: ' + this.connection.connectionId, error);
      });

    this.connection.onreconnected(() => {
        console.log('signlaR onreconnected connectionid: ' + this.connection.connectionId);
      });

    this.connection.start()
        .then(() => {
          this.init();
        
          console.log('signlaR connected! connectionid: ' + this.connection.connectionId)
      })
      .catch(console.error);
  },
  computed: {
    ...mapState('aiAssistent', {
      optimizationRequest: 'optimizationRequest',
      saveRequest: 'saveRequest'
    }),
    ...mapState('signalr', {
      connectionId:'connectionId'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      permissions: 'permissions'
    }),
    isNew() {
      return this.id.toLowerCase() === 'new';
    },
    isBusy (){
      return this.loading || this.initializing || this.instrumentsLoading;
    },
    ...mapGetters('portfolioEdit', {
      previewRequest: 'previewRequest',
      demo: 'demo'
    }),
    ...mapGetters('portfolios', {
      processing: 'processing',
      portfolios: 'items',
    }),
    ...mapGetters('riskprofiles', {
      loading: 'loading',
      riskProfiles: 'activeItems'
    }),
    ...mapGetters('instruments', {
      instrumentsLoading: 'loading',
      classifiers: 'classifiers',
      total: 'total',
      settings: 'settings',
      page: 'page',
      perPage: 'perPage',
      filter: 'filter'
    }),
    availableRiskProfiles() {
      if(this.permissions.canUsePredefinedProfilesOnly) {
        return this.predefinedRiskProfiles;
      } else {
        const o = [...this.predefinedRiskProfiles, ...this.riskProfiles]; 
        o.sort((a, b) => a.name.localeCompare(b.name));
        return o;
      }
    },
    predefinedIndexIDs() {
      if(this.parametersInput?.RiskProfileId) {
        const profile = this.availableRiskProfiles.find(x => x.id === this.parametersInput?.RiskProfileId);
        if(profile && profile.predefined) {
          return (profile.indices || []).map(x => x.indexId);
        }
      }
      return null;
    },
    instrumentsArray(){

      function dynamicSort(property) {
        var sortOrder = 1;

        if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }

        return function (a,b) {
          if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
          } else {
            return a[property].localeCompare(b[property]);
          }        
        }
      }

      const a = Object.values(this.instruments);
      return a.sort(dynamicSort("name"));
    },
    selectedInstrumentsRows(){

      function dynamicSort(property) {
        var sortOrder = 1;

        if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }

        return function (a,b) {
          if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
          } else {
            return a[property].localeCompare(b[property]);
          }        
        }
      }

      const a = Object.values(this.selectedInstruments);
      return a.sort(dynamicSort("name"));
    },
    portfolioVisible() {
      return !this.editInstrumentsEnabled;
    },
    editInstrumentVisible() {
      return this.editInstrumentsEnabled;
    },
    canBeSaved() {
      return this.constraints && this.previewRequest && this.previewRequest.result && !this.previewRequest.error && !this.previewRequest.pending && !this.modified;
    }
  },
  watch: {
    optimizationRequest(nv, ov) {
      if(nv != ov && nv) {
       
        if( nv['Risk Level']) {
          let arp = this.availableRiskProfiles.find(x => x.name = nv['Risk Level']);
            if(arp) {
            this.selectPredefinedIndices(arp);
          } 
        }
        if( nv.Amount >= 0) {
          this.amount = nv.Amount || 0;
        }
        if(nv.MakeRequest) {
          this.makePreview();
        }
       
      }
    },
    saveRequest(nv, ov) {
      if(nv != ov && nv === 'save') {
        this.save();
      }
    },
    connectionId(newValue, oldValue) {
      if(newValue && (newValue !== oldValue) && !this.initialized) {
        if(!this.isNew) {
          this.loadPortfolio({ id: this.id});
        }
      
        this.initialized = true;
      }
    }
  },
  methods: {
    ...mapActions('execution', {
      makeOptimization: 'makeOptimization'
    }),
    ...mapActions('aiAssistent', {
      startChat: 'startChat'
    }),
    ...mapActions('portfolios', {
      create: 'createUserPortfolio',
      update: 'updateUserPortfolio',
      loadPortfolio: 'loadUserPortfolio'
    }),
    ...mapMutations('portfolioEdit', {
      resetPreview: 'RESET_PREVIEW'
    }
    ),
    ...mapActions('portfolioEdit', {
      makePreviewRequest: 'makePreviewRequest',
    }),
    ...mapActions('riskprofiles', {
      loadProfiles: 'load'
    }),
    ...mapActions('instruments', {
      loadClassifiers: 'loadClassifiers',
      setSettings: 'setSettings',
      setFilter: 'setFilter',
      setPage: 'setPage',
      loadInstruments: 'load',
      setIDs: 'setIDs',
    }),
    async loadPredefinedRiskProfiles() {
      const userResp = await explorerHttp.get(`data/User/objects/current`);
      const user = userResp.data;
      if( user.Company?.Id) {
        const resp = await http.get('api/predefined-risk-profiles?companyId=' + user.Company?.Id);
        this.predefinedRiskProfiles = resp.data;
      }
    },
    async init() {
      this.setIDs([]);
      this.initializing = true;

      await this.setSettings({...this.settings, mode: 'Subset'});
      await this.loadClassifiers();
      
      await this.loadProfiles();
      await this.loadPredefinedRiskProfiles();

      const defaultsResp = await http.get(`api/instrument-default`);
      this.defaults = defaultsResp.data;

      if(!this.connectionId) {
         return;
      }
      
      if(!this.isNew) {
        const portfolio =  await this.loadPortfolio({ id: this.id});
       
        let parametersInput = Object.keys(portfolio.constraints).reduce(
          (result, key) => ({
            ...result,
            [capitalize(key[0]) + key.substring(1)]: portfolio.constraints[key],
          }),
          {},
        );
        if(parametersInput['TaaPctTarget']){
          parametersInput['TAAPctTarget'] = parametersInput['TaaPctTarget'];
          delete parametersInput['TaaPctTarget'];
        }
        
        this.title = portfolio.name;
        this.name = portfolio.name;
        if(this.clone){
          this.title = portfolio.name + " (Clone)";
          this.name = "";
        }
        this.currency = portfolio.constraints.currency || this.currentUser.defaultCurrency;
        this.amount = parametersInput.Amount;
        this.benchmark = parametersInput.Benchmark || 'SP500';
        this.instruments = portfolio.instruments.reduce((acc,curr)=> (acc[curr.id]=curr,acc),{});
        this.weightsMode = portfolio.weightsMode;
        this.startDate = portfolio.startDate;
        this.endDate = portfolio.endDate;
        const instrumentWeights = {
          
        };
       
        portfolio.instruments.forEach(i => {
          const e = portfolio.instrumentWeights[i.id];
         
          let min = e?.min === undefined ? null : e?.min;
          let max = e?.max === undefined ? null : e?.max;
          if(min > 0) min = min * 100;
          if(max > 0) max = max * 100;
          instrumentWeights[i.id] = {
            mode: 'input',
            slider: [min, max],
            min: min,
            max: max
          };
        });
        this.instrumentWeights = instrumentWeights;

       // console.log('instrumentWeights', {instrumentWeights, portfolio});
        // parametersInput['RiskTolerance'] = portfolio.constraints.riskTolerance;
        // parametersInput['RiskProfileId'] = portfolio.constraints.riskProfileId;
        parametersInput['SolutionName'] = portfolio.solutionName;
        this.constraints = parametersInput;
        this.parametersInput = parametersInput;

       
     //   this.markAsModified();
        this.makePreview();
      } else {
        await this.resetPreview();
        this.instruments =  defaultsResp.data.reduce((acc,curr)=> (acc[curr.id]=curr,acc),{});
        const instrumentWeights = {
          
        };
        defaultsResp.data.forEach(i => {
          instrumentWeights[i.id] = {
            mode: 'input',
            slider: [0,0],
            min:null,
            max:null
          };
        });
        this.instrumentWeights = instrumentWeights;
        this.markAsModified();
      }
      
      if(this.instruments.length === 0) {

        this.editInstruments();
      } 

      this.initialized = true;

      this.$nextTick(() => {
        this.initializing = false;
      });
    },
    selectInstrument(instrument) { 
      const o = {...this.selectedInstruments};
      o[instrument.id] = instrument;
      this.selectedInstruments = o;
      const instrumentWeights = {...this.instrumentWeights};
      instrumentWeights[instrument.id] = {
        mode: 'input',
        slider: [0,0],
        min:null,
        max:null
      };
      this.instrumentWeights = instrumentWeights;
    },
    unSelectInstrument(instrument) { 
      
      const o = {...this.selectedInstruments};

      if(o[instrument.id]){
        delete o[instrument.id];
      }
      this.selectedInstruments = o;  
      const instrumentWeights = {...this.instrumentWeights};
      instrumentWeights[instrument.id] = {
        mode: 'input',
        slider: [0,0],
        min:null,
        max:null
      };
      this.instrumentWeights = instrumentWeights;
    },
    editInstruments() {
      this.setIDs(this.predefinedIndexIDs);
      this.loadInstruments();
      this.editInstrumentsEnabled = true;
      this.selectedInstruments = {...this.instruments};
    },
    applyEditInstruments() {
      this.instruments = {...this.selectedInstruments};
      const instrumentWeights = {...this.instrumentWeights};
      this.instrumentsArray.forEach(i => {
        instrumentWeights[i.id]  = instrumentWeights[i.id] || {
          mode: 'input',
          slider: [0,0],
          min:null,
          max:null
        };
      });
      this.instrumentWeights = instrumentWeights;
      this.editInstrumentsEnabled = false;
      this.markAsModified();
    },
    cancelEditInstruments() {
      this.editInstrumentsEnabled = false;
    },
    selectPredefinedIndices(profile) {
        this.selectedInstrumentsRows.forEach(item =>{
          this.unSelectInstrument(item);
        });
        const req = {
          skip: 0,
          take: 300,
          ids: (profile.indices || []).map(x => x.indexId)
        };
        http.post('api/instrument-search', req)
        .then(resp => {
        
          //weightsMode
          resp.data.items.forEach(item =>{
            this.selectInstrument(item);
          });

          this.weightsMode = false;
          const instrumentWeights = {};
        
          resp.data.items.forEach(item =>{
            const predefinedProfileIndex = profile.indices.find(x => x.indexId === item.id);
           
            if((predefinedProfileIndex.minWeight !== undefined && predefinedProfileIndex.minWeight !== null) || (predefinedProfileIndex.maxWeight !== undefined && predefinedProfileIndex.maxWeight !== null)) {
              
              this.weightsMode = true;
              instrumentWeights[item.id]  = {
                mode: 'input',
                slider: [predefinedProfileIndex.minWeight || 0,  predefinedProfileIndex.maxWeight || 0],
                min: predefinedProfileIndex.minWeight || 0,
                max: predefinedProfileIndex.maxWeight || 0
              };
              
            } else {
              instrumentWeights[item.id]  = {
                mode: 'input',
                slider: [0, 0],
                min: null,
                max: null
              };
            }
          });
          
          this.instrumentWeights = instrumentWeights;
          this.applyEditInstruments();
        });
    },
    onSolutionDataChanged(v) {
      
      this.constraints = v;
      const oldProfile = this.availableRiskProfiles.find(x => x.id === this.parametersInput?.RiskProfileId);
      this.parametersInput = v;   
      const newProfile = this.availableRiskProfiles.find(x => x.id === this.parametersInput?.RiskProfileId);
      
      if(oldProfile?.id !== newProfile?.id && newProfile.predefined) {
      
        this.startDate = newProfile.startDate || null;
        this.endDate = newProfile.endDate || null;
      
        this.selectPredefinedIndices(newProfile);
      }
    },
    createInstrumentWeightsDto() {
      const instrumentWeights = {};
      this.instrumentsArray.forEach( i => {
        const s = this.instrumentWeights[i.id];
        if(s) {
          let min = s.mode === 'slider' ? s.slider[0] : s.min;
          let max = s.mode === 'slider' ? s.slider[1] : s.max;
          if(min === "") min = null;
          if(max === "") max = null;
          if(min < 0) {
            min = 0;
          }
          if(max > 100) {
            max = 100;
          }
          instrumentWeights[i.id] = { 
            min: min !== null && min !== undefined ? min/100 : null, 
            max: max !== null && max !== undefined ? max/100 : null, 
          };
          
        } else {
          instrumentWeights[i.id] = { min: null, max: null };
        }
        
      });
      return instrumentWeights;
    },
    async makePreview() {
      this.modified = false;
      const instrumentWeights = this.createInstrumentWeightsDto();
      let input = { 
        solutionName: this.constraints.SolutionName,
        instruments: this.instrumentsArray.map(x => x.name),
        instrumentWeights: instrumentWeights,
        weightsMode: this.weightsMode,
        startDate: this.startDate,
        endDate: this.endDate,
      
        data: {
          ...this.constraints, 
          RiskTolerance: undefined,
          RiskProfileId: undefined, 
          SolutionName: undefined, 
          OutSampleMonths: this.constraints.outSampleMonths || this.constraints.OutSampleMonths ||  12, 
          InSampleMonths: this.constraints.inSampleMonths || this.constraints.InSampleMonths || 24,
          Amount: this.amount,
          Currency: this.currency,
          Benchmark: this.benchmark
         
        } 
      };
  
      this.makePreviewRequest({ input });
    },
    getErrorMessage (error) {
      if(error && error.includes('Infeasible')) {
        return `
          <h5>Requested portfolio is infeasible<h5/> <br/> <br/>
          This is usually caused by mutually exclusive constraints or a list of assets that is not sufficiently large and diverse. <br/>
          Try a larger list of assets and relax your weight and asset class limits.`
      } else if(error && error.includes('Failed')) {
        return `
          <h5>Unable to form a portfolio</h5> <br/> <br/>
          There may be no portfolio that meets all your requirements. Occasionally this can be caused by data problems, such as insufficient history. <br/>
          Try adjusting or enlarging the list of assets and relax your weight and asset class limits.`
      } else if(error) {
        return error;
      }
      return "An error has occurred. Please try again later or connect to administrator.";
    },
    markAsModified() {
      this.modified = true;
    },
    async save () {

      this.invalidName = false;
      const o = this.portfolios.find(x => x.name.toLowerCase().trim() ===  this.name.toLowerCase().trim() && x.id !== this.id);
    
      if(o) {
        this.invalidName = true;
        return;
      }
      const c = this.constraints || this.parametersInput;
      const cn = {
        ...c, 
        RiskTolerance: c.RiskTolerance, 
        RiskProfileId: c.RiskProfileId, 
        SolutionName: c.SolutionName, 
        OutSampleMonths:c.outSampleMonths || c.OutSampleMonths ||  12, 
        InSampleMonths: c.inSampleMonths || c.InSampleMonths || 24,
       
        Amount: this.amount,
        Currency: this.currency,
        Benchmark: this.benchmark
      } ;
      var keys = Object.keys(cn);
      keys.forEach(key => {
        if(key.indexOf('targetIndex') === 0) {
          cn[key.toUpperCase()] = cn[key];
        }
      });
      const instrumentWeights = this.createInstrumentWeightsDto();
    
      const input = {
        name: this.name,
        weights:  this.previewRequest ? this.previewRequest.result.data.compositionWeights : null,
        solutionName: c.SolutionName,
        riskProfileId: c.RiskProfileId,
        riskTolerance: c.RiskTolerance,
        startDate: this.startDate,
        endDate: this.endDate,
      //  stat: this.previewRequest.result.data,
        constraints: cn ,
        instruments: this.instrumentsArray.map(x => {
          return { id: x.id, name: x.name };
        }),
        instrumentWeights: instrumentWeights,
        weightsMode: this.weightsMode,
        currency: this.currency,
        benchmark: this.benchmark,
        amount: this.amount
      };

      if(this.id.toLowerCase() === 'new' || this.clone) {
        await this.create({input});
      } else {
        await this.update({id: this.id, input});
      }
      Swal.fire(
        'Save Portfolio',
        'Portfolio was saved successfully!',
        'success'
      );
      this.$router.push("/");
    },
    onInstrumntWeightModeChanged(id, mode) {
      this.instrumentWeights[id].mode = mode;
    },
    onInstrumntWeightMinChanged(id, v) {
      if(v === "") {
        v = null;
      }
      if( typeof v === 'string' || v instanceof String) {
        v = Number(v);
      }
      const s = this.instrumentWeights[id];
      if(s.mode === 'input') {
        if(v === null || v === undefined) {
          s.slider = [null,null];
        } else {
          if(s.max != undefined && s.max != null && s.max < v) {
            s.max = v;
          }
          s.slider = [v, s.max || v];
        }
      }
    },
    onInstrumntWeightMaxChanged(id, v) {
      if( typeof v === 'string' || v instanceof String) {
        v = Number(v);
      }
      const s = this.instrumentWeights[id];
      if(s.mode === 'input') {
        if(v === null || v === undefined) {
          s.slider = [null,null];
        } else {
          if(s.min != undefined && s.min != null && s.min > v) {
            s.min = v;
          }
          s.slider = [s.min || v, v];
        }
      }
    },
    onInstrumntWeightSliderChanged(id, v) {
      const s = this.instrumentWeights[id];
      if(s.mode === 'slider') {
        s.min = v[0];
        s.max = v[1];
      }
    },
    beforeDestroy(){
      if( this.connection){
        this.connection.stop();
        this.connection = null;
      }
    },
    onProgress($event) {
      console.log('onProgress');
      console.log($event)
    },
    hasStartedGeneration() {
      console.log('hasStartedGeneration');
    },
    generateReport () {
      console.log('generateReport');
      this.pdfProcessing = true;
      window.setTimeout(()=>this.$refs.html2Pdf.generatePdf(), 500);
    },
    hasGenerated () {
    
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then(() => {
        this.pdfProcessing = false;
      }).save();
    },
    validateInput() {
      this.name = this.name.replace(/[^a-zA-Z0-9_\s]/g, "");
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="isBusy"/>

    <div v-if="!initializing && initialized">
      <div class="mb-4 row">
        <div class="col text-right">
          <div class="btn-group mr-2 mb-2 mb-sm-0" v-if="canBeSaved">
            <button type="button" class="btn btn-primary" @click="generateReport" :disabled="pdfProcessing || processing || !name">
              <i class="fa fa-spin fa-spinner" v-if="pdfProcessing"></i>
              <i class="fa fa-file-pdf" v-if="!pdfProcessing"></i>
            </button>
          </div>

          <button class="btn revers btn-c8 " @click="makePreview" v-if="constraints && previewRequest" :disabled="processing || (previewRequest && previewRequest.pending) || makingOptimization">
            <span class="btn-content">
              <i class="fas fa-play-circle mr-2" v-if="!processing && !(previewRequest && previewRequest.pending) && !makingOptimization"/>  
              <i class="fa fa-spin fa-spinner mr-2" v-if="processing || (previewRequest && previewRequest.pending) || makingOptimization"/>
              Calculate Portfolio
            </span>
          </button>

          <button class="btn revers btn-c8 ml-2" v-if="canBeSaved || !isNew" :disabled="processing || !name && makingOptimization" @click="save">
            <span class="btn-content">
              <i class="fa fa-save mr-2" v-if="!processing"/>  
              <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>  
              Save Portfolio
            </span>
          </button>
        </div>
      </div>

      <vue-html2pdf
        v-if="canBeSaved"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="title"
        :pdf-quality="3"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <!-- Logo >>>> -->
          <img src="@/assets/images/c8_logo_main.jpg" class="img-fluid rounded mx-auto d-block" style="margin-left:100px;margin-right:100px;width:600px; margin-top:250px">
          <div class="d-flex justify-content-center" style="margin-bottom:170px; margin-top:150px">
            <h4 class="display-4">C8 Studio</h4>
          </div>
          <div class="d-flex justify-content-center">
            <h2>C8 Studio Available Indices</h2>
          </div>

          <div class="html2pdf__page-break" />
          <!-- Logo <<<< -->

        <!-- Main chart and stat >>>> -->
        <div style="margin:50px" class="print">
          <h5>
            {{title}}
          </h5>
          <hr />

            <h5 class="card-title" style="margin-top:20px">{{$t('pages.indexView.indexPerformance-title')}}</h5>
            <h6 class="card-subtitle text-muted" style="margin-top:10px; margin-bottom:20px"><span v-html="$t('pages.indexView.indexPerformance-subtitle')"></span></h6>
            <div class="card-text">
              <NetAssetValueGrowthChart 
                :stat="previewRequest.result.data.netAssetValueGrowthCharts" 
                :instruments="instrumentsArray"
                :colors="previewRequest.colors"
                hide-chart-hint
                :pdf="true"
              />
            </div>  

            <div>
              <main-stat 
                :stat="previewRequest.result.data.statistic" 
                :instruments="instrumentsArray"
                show-only-first
                first-title="Portfolio"
              />
            </div>
          </div>

          <div class="html2pdf__page-break" />
          <!-- Main chart and stat <<<< -->

          <!-- Monthly performance >>>> -->
          <div style="margin:20px; margin-top:80px">
            <monthly-performance 
              :stat="previewRequest.result.data.monthlyPerformance" 
              :instruments="instrumentsArray"
              :normalized="normalised"
              :pdf="true"
            />
          </div>
          <div class="html2pdf__page-break" />
          <!-- Monthly performance <<<< -->

          <!-- Weights Pie Chart >>>> -->
          <div style="margin:50px">
            <weights 
              :weights="previewRequest.result.data.compositionWeights" 
              :children="instrumentsArray" 
              :pdf="true"
            />
          </div>
          <!-- Weights Pie Chart <<<< -->

        <!-- Risk Profile >>>> -->
        <div style="margin:50px; margin-top:80px">
          <parameters 
            :input="parametersInput" 
            @apply="onSolutionDataChanged"
            :riskProfile="availableRiskProfiles"
            v-if="availableRiskProfiles.length > 0"
            :pdf="true"
          />
        </div>
        <div class="html2pdf__page-break" />
        <!-- Risk Profile <<<< -->

          <!-- Contact >>>> -->
          <div style="position:relative; text-align: center; margin-top:80px;">
            <img style="object-fit: contain; width:600px"
              src="@/assets/images/C8Contacts.jpg" />
          </div>
          <!-- Contact <<<< -->

        </section>
      </vue-html2pdf>

      <div class="row" v-if="portfolioVisible">
        <div class="col-12">
          <div class="email-leftbar p-0 " >
            <div class="card cardc8">
              <div class="form-group" v-if="invalidName">
                <div class="alert alert-warning mx-3 mt-3">
                  Name is not unique!
                </div>
              </div>
              <div class="card-content ml-3 mr-3 mb-3">
                <label>Name</label>
                <input class="form-control" v-model="name" @input="validateInput"/>
              </div>

              <div class="card-content ml-3 mr-3 mb-3">
                <label>Currency</label>
                <select class="form-control" v-model="currency">
                  <option value="USD" selected>
                    United States Dollar (USD)
                  </option>
                  <option value="EUR">
                    Euro (EUR)
                  </option>
                  <option value="GBP">
                    Pound Sterling (GBP)
                  </option>
                  <option value="JPY">
                    Japanese Yen (JPY)
                  </option>
                  <option value="AUD">
                    Australian Dollar (AUD)
                  </option>
                  <option value="NZD">
                    New Zealand Dollar (NZD)
                  </option>
                  <option value="CAD">
                    Canadian Dollar (CAD)
                  </option>
                  <option value="CHF">
                    Swiss Franc (CHF)
                  </option>
                  <option value="NOK">
                    Norwegian Krone (NOK)
                  </option>
                  <option value="SEK">
                    Swedish Krona (SEK)
                  </option>
                </select>
              </div>

              <div class="card-content ml-3 mr-3 mb-3">
                <label>Amount</label>
                <input class="form-control" v-model="amount"/>
              </div>

              <!-- <div class="card-content ml-3 mr-3 mb-3">
                <label>Benchmark</label>
                <select class="form-control" v-model="benchmark">
                  <option value="SP500" selected>
                    S&P 500
                  </option>
                </select>
              </div> -->
            </div>

          <div >
            <parameters :input="parametersInput" @apply="onSolutionDataChanged" :riskProfiles="availableRiskProfiles"   v-if="availableRiskProfiles.length > 0">
            </parameters>

            <div class="card cardc8">
              <div class="card-content m-3">
                <div class="card-header bg-transparent d-flex justify-content-between">
                  <h5>Instruments</h5>
                  <span>
                    <b-form-checkbox v-model="weightsMode" switch 
                      style="display:inline-block;">

                    </b-form-checkbox>
                    <b>Weights</b>
                  </span>
                 
                </div>
                <em class="text-muted" v-if="instrumentsArray.length === 0 ">No instruments found...</em>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in instrumentsArray" :key="item.id"  style="position: relative; padding-right:30px;">
                    {{item.name}}
                    <div v-if="weightsMode">
                      <div class="mb-1 col">
                        <vue-slider 
                          style="margin-top:5px;" 
                          v-model="instrumentWeights[item.id].slider" 
                          :min="0" 
                          :max="100" 
                          :interval="1"
                          @drag-start="onInstrumntWeightModeChanged(item.id, 'slider')"
                          @change="v => onInstrumntWeightSliderChanged(item.id, v)"
                        ></vue-slider> 
                      </div>
                       <div class="row">
                        <div class="col" >
                          <input class="form-control form-control-sm "  
                            type="number" 
                            min="0" 
                            max="100" 
                            @focus="onInstrumntWeightModeChanged(item.id, 'input')"
                            @change="$event => onInstrumntWeightMinChanged(item.id, $event.target.value)"
                            v-model.number="instrumentWeights[item.id].min"/>
                        </div>
                       
                        <div class="col" >
                          <input class="form-control form-control-sm"  
                            type="number" 
                            min="0" 
                            max="100" 
                            @focus="onInstrumntWeightModeChanged(item.id, 'input')"
                            @change="$event => onInstrumntWeightMaxChanged(item.id, $event.target.value)"
                            v-model.number="instrumentWeights[item.id].max"/>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn  btn-sm btn-c8 float-right mt-3"  @click="editInstruments">
                  <span class="btn-content">
                    <i class="fa fa-pen mr-2"/> Edit
                  </span>
                </button>
              </div>
            </div>
          </div>
            
        </div>
          <div class="email-rightbar p-0" >
            <div class="alert alert-danger text-center" v-if="previewRequest && previewRequest.error && !modified">
              <span v-if="previewRequest.error.internal">
                An error has occurred. Please try again later or connect to administrator.
              </span>
              <p v-if="!previewRequest.error.internal">
              <span v-html="getErrorMessage(previewRequest.error.message)"></span>
              </p>
            </div>
            <div class="text-center mb-4">
              <button class="btn revers btn-c8 btn-lg " @click="makePreview" v-if="constraints && !previewRequest" :disabled="processing || (previewRequest && previewRequest.pending)  && !makingOptimization">
                <span class="btn-content">
                  <i class="fas fa-play-circle mr-2" v-if="!processing && !(previewRequest && previewRequest.pending) && !makingOptimization"/>  
                  <i class="fa fa-spin fa-spinner mr-2" v-if="processing || (previewRequest && previewRequest.pending)  || makingOptimization"/>
                  Calculate Portfolio
                </span>
              </button>
            </div>
            <div style="min-height:300px; position: relative;">
              
              <div v-if="(!previewRequest || !((previewRequest || {}).result))  && !(previewRequest || {}).pending" style="min-height:300px; height:300px;" class="text-center">
                <h1 class="mt-4">No Data Available</h1>
                <div style="position: absolute; top:0; left:0; right:0; bottom:0; background: rgba(255, 255, 255, 0.6);" class="text-center">
                 
                </div>
              </div>
              <div v-if="previewRequest && previewRequest.result">
                <div class="row mb-4">
                  <div class="col-xl-8">
                    <NetAssetValueGrowthChart 
                      :stat="previewRequest.result.data.netAssetValueGrowthCharts" 
                      :instruments="instrumentsArray"
                      :colors="previewRequest.colors"
                      hide-chart-hint
                      >
                    </NetAssetValueGrowthChart>
                  </div>
                  <div class="col-xl-4">
                    <main-stat 
                      :stat="previewRequest.result.data.statistic" 
                      :instruments="instrumentsArray"
                      show-only-first
                      first-title="Portfolio"
                      >

                    </main-stat>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                   <monthly-performance 
                      :stat="previewRequest.result.data.monthlyPerformance" 
                      :instruments="instrumentsArray"
                      :normalized="normalised"
                    >
                    </monthly-performance>
                  </div>
                </div>
                <weights 
                    :weights="previewRequest.result.data.compositionWeights" 
                    :children="instrumentsArray" 
                  >
                  </weights>
              </div>
              <div style="position: absolute; top:0; left:0; right:0; bottom:0; background: rgba(255, 255, 255, 0.6);" class="text-center" v-if="(previewRequest && previewRequest.pending) || makingOptimization">
                <span v-if="previewRequest && previewRequest.pending" aria-hidden="true" class="spinner-border text-dark" style="width: 60px;height: 60px; margin-top:100px"></span><br/><br/><br/>
                <span v-if="previewRequest && previewRequest.pending">Preparing...</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="editInstrumentVisible">
        <div class="col">
          <div class="email-leftbar" style="padding:0">
            <filter-panel></filter-panel>
            <div class="card cardc8 mt-4">
              <div class="card-content m-3">
                <div class="card-header bg-transparent d-flex justify-content-between"><h5>Selected Instruments</h5></div>
                <em class="text-muted" v-if="selectedInstrumentsRows.length === 0 ">No selected instruments found...</em>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in selectedInstrumentsRows" :key="item.id"  style="position: relative; padding-right:30px;">
                    {{item.name}}
                    <a href="javascript:void(0)" @click="unSelectInstrument(item)" class="text-muted " style="position: absolute; right:10px; top:10px;">
                      <i class="mdi mdi-close"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <button class=" mt-2 btn btn-light rounded-pill" @click="cancelEditInstruments">
              Cancel
            </button>
            <button class=" mt-2 btn btn-c8 float-right" @click="applyEditInstruments">
              <span class="btn-content"> 
                <i class="fa fa-save mr-2"/>
                Apply Changes
              </span>
            </button>
          </div>
          <div class="email-rightbar mb-3 p-0">
            <div class="card p-0 cardc8">
              <div class="card-content m-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-header bg-transparent d-flex justify-content-between mb-2"><h5>Instruments</h5></div>
                  </div>
                </div>
                <list
                  :ids="predefinedIndexIDs"
                  :allow-select="true"
                  :selected="selectedInstruments"
                  @select="selectInstrument"
                  @unselect="unSelectInstrument"
                  :statColumns="statColumns"
                >
                  
                </list>
                <b-pagination
                  class="mr-3 float-right"
                  :value="page"
                  :total-rows="total"
                  :per-page="perPage"
                  @input="setPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <chatgpt 
      v-if="isNew && initialized"
      case-state="CreatePortfolio"
      auto-user-prompt="Hi, What Risk Levels do you have?"
    >

    </chatgpt>
    <chatgpt 
      v-else-if="initialized"
      case-state="ReviewPortfolio"
      auto-user-prompt="Hi, What is the Performance this year and what positions? Also, what are the next steps?"
    >

    </chatgpt>
  </Layout>
</template>